<template>

    <div class="container-fluid posts-carousel-container" v-if="dashboard && session && product && content.length" >

      <!-- preview dialog -->
      <div class="row">
        <div  class="col-md-11 offset-1">
          <PreviewDialog :post="preview" :policy="policy" @on-action="onAction" />
        </div>
      </div>            

      <!-- channel picker dialog for send -->
      <div class="row" v-if="post">
        <div  class="col-md-11 offset-1">
          <ChannelPickerModal v-if="post" :schedule="schedule" :dashboard="dashboard" :channel="channel" :post="post" :show="showSelectChannels" :partner="partner" :sendToTarget="sendToTarget" @on-close="close" @on-send="send" />
        </div>
      </div>      

      <!-- schedule dialog for send -->
      <div class="row">
        <div  class="col-md-11 offset-1">
          <PostActionsDialog id="schedule-modal" :dashboard="dashboard" :post="post" :partner="partner" @on-action="onAction" @on-send-to="onSendTo" @on-scheduled="onScheduled" />
        </div>
      </div>

      <div class="row">
        <div class="header-container" :class="colsFor('header')"  v-if="mode!=='onboard'">
          <ResultsHeader class="header" :content="content" @on-action="onAction" :errors="errors" />
        </div>
      </div>

      <div class="row">
        <div class="results-container" :class="colsFor('content')">
            <div  v-for="post in content" :key="post._id" @click="toggleEdit(post)">
              <ResultBox 
                :post="post"
                :errors="errors"
                :policy="policy"
                :session="session"
                :mode="mode"
                :state="state"
                @on-select="onSelect"
                @on-action="onAction"
                @on-errors="onErrors"
                :selectedPost="selectedPost"                    
                @content-updated="updateContent" />
            </div>
        </div>
        <div class="indicator-container" v-if="selectedPost && ($mq === 'lg'||$mq==='xl')" :class="colsFor('indicator')">
          <SuccessIndicator class="indicator" :post="selectedPost" :mode="mode" :state="state" :help="help" />
        </div>
      </div>

    </div>

</template>

<style lang="scss" >

.posts-carousel-container {

  .indicator-container {
    position: sticky;
    padding: 0px;
    top: 0px;
  }

  .header-container {
    max-width: 50%;
    overflow: hide;    
    z-index: 1000;
  }

  .results-container {
    margin-top: 0px;
    overflow: auto;
    max-height: calc(100vh - 215px);
    overflow-y: scroll;
  }

}

</style>

<script>

'use strict'

import ChannelPickerModal from '@/components/ChannelPickerModal'
import PreviewDialog from '@/components/PreviewDialog'
import PostActionsDialog from './PostActionsDialog'
import SuccessIndicator from './SuccessIndicator'
import ResultsHeader from './ResultsHeader'
import ResultBox from './ResultBox'

import { actions, store, getters } from '@/services/store'
import Selections from '@/services/selections'
import { includes } from '@/services/product'
import Hootsuite from '@/services/hootsuite'
import { media } from '@/services/constants'
import Session from '@/services/session'
import Logger from '@/services/logger'
import Vue from 'vue'

const CHANNEL_ORDER = ['facebook','twitter','linkedin','instagram']

export default {

  name: 'PostsCarousel',

  data() {
    return {
      idx:-1,
      errors:[],
      channel:'',
      variants:[],
      partner:'',
      schedule:'',
      busy: false,
      status:false,
      ready: false,
      busyMessage:'',
      sendToTarget:'',
      statusMessage:'',
      post:undefined,     
      user:undefined, 
      preview: undefined,      
      postsRemaining: false,
      selections:[],
      embeddedUpload: false,
      showAIOriginals: '*',
      embeddedUploadMessage:'',
      showSelectChannels:false,
      hootsuite: { schedule: Session.get('hootsuite-schedule','day') }
    }
  },

  props: {

    dashboard: {
      type: Object,
      required:true
    },

    session: {
      type: Object,
      required:true
    },

    policy: {
      type: Object,
      required:true
    },

    content: {
      type: Array,
      required:true
    },

    selectedPost: {
      type:Object,
      required:false
    },

    selectedPlatform: {
      type:String,
      default:''
    },

    state: {
      type: String,
      default:''
    },

    mode: {
      type:String,
      default:''
    },
    
    help: {
      type:String,
      default:''
    },    

  },

  computed: {

    channels() {

      let results = includes('connect-third-party,connect-lately')? Object.keys(this.session.postsRemaining).reduce((acc,key)=>{
        const connected = this.dashboard.channels.filter((ch)=>{return ch.type===key})
        if ( media[key] && connected.length && this.session.postsRemaining[key] ) {
          acc.push(key)
        }
        return acc
      },[]) : Object.keys(this.session.postsRemaining).reduce((acc,key)=>{
        if ( this.session.postsRemaining[key] ) {
          acc.push(key)
        }
        return acc
      },[])

      // reorder
      results = CHANNEL_ORDER.reduce((acc,key)=>{
        if ( results.includes(key) ) {
          acc.push(key)
        }
        return acc
      },[])

      return results
    }

  },

  async created() {

    this.user = getters.user()
    this.product = getters.product()
    this.partner = getters.partner()

    this.channel = this.channels.length? this.channels[0] : ''
    this.showAIOriginals = '*' // this.session.isGenerative? '*' : 'v1'
  },

  watch: {
    selectedPost() {
      this.errors = []
    },
    selectedPlatform() {
      this.errors = []
    }
  },

  methods: {

    colsFor(section) {
      let result, indicator = this.$mq === 'lg' || this.$mq === 'xl'
      switch ( section ) {
        case 'indicator': {
          result = indicator? '3' : '0'
          break
        }
        case 'header':
        case 'content': {
          result = indicator? '7' : '5' 
          break;
        }
        case 'help': {
          result = indicator? '3' : '0' 
          break;
        }
      }
      result = `col-md-${result}`

      return result
    },

    onErrors(errors) {
      this.errors = errors
    },

    /**
     * states 'initial','generated'
     * @param {} state 
     * @param {*} substate 
     */

    async setOnboardingState(state) {
      console.log('setOnboardingSTate',state)
      this.user.onboarding.state = state 
      await actions.patchUser(this.user._id,'onboarding',this.user.onboarding)
    },

    onAction(evt) {

      // console.log('PostsCarousel.onAction',{mode:this.mode,action:evt.action})

      switch( evt.action ) {
        case 'content-edited': {
          if ( this.mode === 'onboard' ) {
            Vue.nextTick(()=>{
              this.$emit('on-action',evt)
            })
          }
          break;
        }
        case 'content-previewed': {
          if ( this.mode === 'onboard' && this.state === 'preview' ) {
            this.$emit('on-action',evt)
          }
          break;
        }        
        case 'cancel-schedule': {
          this.post = undefined
          break;
        }
        case 'schedule': {
          this.post = evt.post
          break;
        }
        case 'preview': {
          if ( this.preview ) {
            this.preview = undefined
          }
          this.preview = evt.post;
          break;
        }
        case 'preview-closed': {
          this.preview = undefined;
          this.$emit('on-action',evt)
          break;
        }
        case 'schedule-selections': {
          this.post = Selections.items()[0]
          break;
        }
        
        default: {
          this.$emit('on-action',evt)
        }
      }

    },

    onScheduled(schedule) {
      this.schedule = schedule
      this.showSelectChannels = true
    },

    onSelect(postId) {
      const idx = this.selections.indexOf(postId)
      if ( idx === -1 ) this.selections.push(postId)
      else this.selections.splice(idx,1)
    },

    toggleEdit(post) {
      this.$emit('on-post-selected',post)
    },

    close(method) {
      this.showSelectChannels = false
      this.schedule = ''
    },

    titleCase(txt) {
      const frags = txt.split(' ')
      frags.forEach((f,idx)=>{
        frags[idx] = f = f.charAt(0).toUpperCase()+f.substring(1)
      })
      return frags.join(' ')
    },

    async send(args) {

      // console.log('PostsCarousel.send()', JSON.stringify(args,0,1), JSON.stringify(this.dashboard,0,1))

      Selections.clear()

      const channels = args.channels
      const partner = getters.partner()
      const dashboard = getters.dashboard()

      // identify selected channels
      const selectedChannels = Object.keys(channels).filter((key)=>{
        return channels[key]
      }).filter((ch)=>{
        return true // ch.content
      }).map((key)=>{
        return {
          channel:key,
          dashboard: dashboard._id,
          type: dashboard.channels.find((ch)=>{return ch && ch.content && ch.content.id==key}).type // do not use identity equality!
        }
      })

      try {

        if ( this.schedule ) {

          const pickForMe =  ( this.schedule === 'pick-for-me' )
          if ( pickForMe ) {
            // derive this from a server call
            this.schedule = new Date().toISOString()
          }

          const options = {
            status:'DT',
            unit: 'd',
            number: 1,
            interval: 1,
            noPublish: true,
            startingDate: this.schedule,
            channels: selectedChannels,
            pickForMe: pickForMe,
            posts: [ this.post._id ],
            timeOfDay: [this.schedule]
          }

          console.log('scheduling', JSON.stringify(options,0,1))

          const resp = await actions.scheduleContent( this.dashboard._id, options, [this.post])
          this.$emit('on-status-message',{
            status: 'success',
            title: 'Post Scheduled!',
            message: `You can find it in your ${partner==='lately'? 'Scheduled & Published' : 'Outbox'}`
          })

        } else {

          const channelType = (this.post.channel || this.post.contentType)
          const schedule = this.partner === 'hootsuite'? args.schedule : ''
          await actions.sendTo( this.session, this.post, this.partner, selectedChannels, schedule, false, this.sendToTarget === 'queue'? 'queue':'partner' )

          const title = this.sendToTarget==='queue'? 'Post Saved' : `Post sent to ${this.titleCase(this.partner)}`
          const message = this.sendToTarget==='queue'? `You can find it in your ${this.partner==='lately'? 'Scheduler':'Drafts'}` : `You can find it in your ${this.partner==='lately'? 'Scheduled & Published':'Drafts'}`

          this.$emit('on-status-message',{
            status: 'success',
            title: title,
            message: message
          })

        }

        this.onPrevNext(0,false,true)


      } catch( err ) {

        console.error(err, err.message)
        let msg = err.message || `We seem to be having difficulties at the moment and have logged an error. You might want to try one more time, or come back in a little while.`
        msg = err.message && err.message.includes('unauthorized')? 'We were unable to send this content to Hubspot - please reauthorize your channel and try again' : msg
        this.$toasted.error(msg)
      } finally {
        this.busy = false
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        this.close() // close event nulls this.schedule
      }

    },

    // event handler from child components
    async onSendTo(args) {

      // console.log('PostsCarousel.onSendTo',JSON.stringify(args,0,1))

      try {

        if ( args.dest === 'hootsuite' && getters.isIframe('hootsuite') ) {

          // summarize attachments
          const acc = (this.post.attachments||[]).reduce((acc,a)=>{
            const type = a.mimetype.split('/')[0]
            acc.total += 1
            acc[type] += 1
            return acc
          },{audio:0,video:0,image:0,total:0})

          // show progress indicator
          this.embeddedUploadMessage = acc.video? 'Please wait while we prepare and upload your video' : 'Please wait while we upload your content'
          this.embeddedUpload = true

          // conditionally wait for attachments, show hsapi composer
          this.post.dashboard = getters.dashboard()._id
          await Hootsuite.composeAndAttachFor(this.post)

          // hide progress indicator
          this.embeddedUploadMessage = ''
          this.embeddedUpload = false

          this.$emit('on-status-message',{
            status: 'success',
            title: 'Post Sent to Hootsuite!',
            message: 'You can find it in your Hootsuite Dashboard and Lately Outbox'
          })

          Logger.postsSent([this.post],'hootsuite')

          this.onPrevNext(1,false,true)

        } else {

          this.sendToTarget = args.dest

          if ( args.publish ) {
            this.post.publishAt = new Date() // now
          } else {
            this.post.publishAt = 'undefined'
          }

          // save before send..
          await this.updateContent('sendTo',true)

          switch ( args.dest ) {

            case 'trash': {

              this.busy = true
              const dashboard = getters.dashboard()
              await actions.removePost(dashboard._id,this.post)
              // this.$toasted.success(`Post removed`)
              this.onPrevNext(0,false,true)

              this.$emit('on-status-message',{
                status: 'success',
                title: 'Post Removed',
                message: ''
              })

              this.busy = false
              break;

            }

            case 'export': {

              try {
                await actions.addToExport(this.session,this.post)
                this.$toasted.success(`Content added to export`)
                this.onPrevNext(0,false,true)
              } catch( err ) {
                this.$toasted.error(`Unable to ad content to export`)
              }
              break;
            }

            default: {

              // show the channel picker
              this.showSelectChannels = true
              this.doPublish = true
              break;

            }
          }
        }

      } catch( err ) {
        console.error(err)
        this.$toasted.error(err.message)
      }

    },

    onChannelSelected(channel) {
      this.channel = channel
    },

    async onPrevNext(dir,ev,evict) {
      if ( ev ) {
        ev.stopPropagation()
        ev.preventDefault()
      }
      const pr = this.session.postsRemaining
      if ( evict ) {

        const idx = this.content.indexOf(this.post)
        if ( idx !== -1 ) {
          this.content.splice(idx,1)
          pr[this.channel] -= 1
          pr.all -= 1
          this.post = undefined
          this.schedule = ''
        }

        // if necessary move the channel selection along
        if ( !pr[this.channel] ) {
          this.idx = -1
          this.post = undefined
          this.schedule = ''
          const keys = Object.keys(pr).filter((key)=>{
            return media[key] && pr[key]
          })
          if ( keys.length ) {
            this.channel = keys[0]
          } else return
        }
      }
      const next = dir + this.idx
      const length = this.content.length
      const last = this.content.length -1
      if ( length && next >= 0 && next <= last ) {
        this.idx = next
        this.post = this.content[next]
        this.schedule = ''
      }
    },

    async updateContent(source,immediate,score) {

      const clearTimer = () => {
        if ( this.timer ) {
          clearTimeout(this.timer)
          this.timer = false;
        }
      }

      const doUpdate = async () => {

        clearTimer()

        if ( this.post ) {

          const values =  {
            _id: this.post._id,
            content: this.post.content,
            publishAt: this.post.publishAt,
            attachments: this.post.attachments
          }

          if ( this.post.publishAt ) {
            values.publishAt = this.post.publishAt
          }

          if ( this.post.rank && this.post.rank.successIndicator ) {
            values.score = this.post.rank.successIndicator
          }

          const resp = await actions.updatePost( this.session, values )

          if ( resp && this.post ) this.post.preview_cache = resp.preview_cache

        }

      }

      if ( immediate) {
        await doUpdate()
      } else {
        clearTimer()
        this.timer = setTimeout(doUpdate,500)
      }

    },

    onScoreUpdated(newScore) {
      if ( !this.post.rank || this.post.rank.successIndicator != newScore ) {
        this.post.rank = this.post.rank || {}
        this.post.rank.successIndicator = newScore
        this.updateContent('onScoreUpdated',false,newScore)
      }
    },

  },

  components: {
    ChannelPickerModal,
    PostActionsDialog,
    SuccessIndicator,
    ResultsHeader,
    PreviewDialog,
    ResultBox
  }

}
</script>



