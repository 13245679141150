<template>

  <div class="session-info d-flex flex-column">

    <!-- created -->
    <div>
      <span class="source-date">Generated on {{session.createdAt|date('MM/DD/YY')}}</span>
      <span class="source-date" v-if="session.tone!=='natural'"> in <b>{{ session.tone | titlecase }}</b> voice</span>
    </div> 

    <!-- video source summary -->
    <div >

      <div v-if="session.config.source==='video'">
        <span class="source-title"><b>Media Upload</b></span>
        <p class="source-info">{{session.config.steps.source.source.duration|duration}} / {{session.config.steps.source.source.size|magnitude}}B </p>
      </div>

      <!-- text source summary -->
      <div v-else>

        <!-- url source -->
        <div v-if="session.config.steps.source.type==='url'">

          <!-- with preview -->
          <div v-if="session.config.steps.source.preview">
            <span class="source-title"><b>Link: {{session.config.steps.source.preview.title | truncate(90,'...') }}</b></span>
            <p class="source-info"><a target="_" :href="session.config.steps.source.url">{{session.config.steps.source.url | truncate(90,'...')}}</a></p>
          </div>

          <!-- without preview -->
          <div v-else>
            <span class="source-title"><b>Link: (No preview available)</b></span>
            <p class="source-info"><a target="_" :href="session.config.steps.source.url">{{session.config.steps.source.url | truncate(90,'...')}}</a></p>
          </div>
        </div>

        <!-- pasted text -->
        <div v-else>
          <span class="source-title"><b>Text: {{session.config.steps.source.text | truncate(90,'...')}}</b></span>
          <p class="source-info">{{session.config.steps.source.text.length}} Characters</p>
        </div>

      </div>

    </div>

    <!-- actions at bottom -->
    <div class="mt-auto session-actions" >
      <b-link v-if="session.status.bucket==='transcribed'" class="dashboard-session-action" @click="onAction('view')"><b>Review Transcript</b></b-link>
      <b-link v-if="session.status.bucket!=='complete' && session.status.bucket!=='failed'" class="dashboard-session-action" @click="onAction('view')"><b>View Progress</b></b-link>
      <b-link v-if="session.status.bucket==='complete' && session.postsRemaining.all" class="dashboard-session-action" @click="onAction('view')"><b>View Results</b></b-link>
      <b-link  class="dashboard-session-action quiet" @click="onAction('regenerate')">Generate Again</b-link>  
      <b-link class="dashboard-session-action quiet" @click="onAction('remove')">Remove</b-link>
    </div>

  </div>

</template>

<script>

export default {

  name: 'SessionInfo',

  props: {
    session: {
      type: Object,
      required: true
    }
  },

  methods: {

    onAction(action) {
      this.$emit('on-action', {
        action: action,
        session: this.session
      })
    }

  }

}

</script>

<style lang="scss" >

.session-info {

  font-family: Noto Sans;

  height: 100%;

  .source-date {
    margin-bottom: 6px;
    color: grey;
    font-size: 12px;
  }

  .source-link {
    margin-top: 6px;
  }

  .source-info {
    margin-bottom: 6px;
    padding-bottom: 5px;
    font-size: 0.7em!important;
  }

  .source-title {
    font: 1.3em;
    font-weight: bold;
  }

  .session-actions a {
    margin-right: 28px;
  }

  .session-actions a.quiet {
    color: grey;
  }
}

</style>
