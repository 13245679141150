<template>
    <div class="onboarding-nav ">
      <div class="row">
        <div class="col-md-12 text-center intro">
          <h3>This is a quick, interactive tutorial to show you how to use Lately like a rockstar!</h3>          
        </div>
        <div class="col-md-8 offset-2">
          <div class="step-container">
            <div class="step" v-for="(s,idx) in steps" :class="s.ids.includes(state)?'active':''">
              <div class="index">{{idx+1}}</div>
              <div class="name">{{s.name}}</div>
              <div class="cta">{{s.cta}}</div>              
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<style lang="scss" >
.onboarding-nav {

  padding: 10px 30px 10px 30px;
  background-color: #2d2d2d;
  z-index: 1000;

  .intro {
    h3 {
      margin: 25px;
      font-size: 20px;
      color: #c8c8c8;
    }
  }

  .step-container {
    display: flex;
    font-family: Arial;    
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    color: #5a5a5a;    

    .index {
      position: relative;
      z-index: 5;
      display: flex;
      font-size: 22px;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #5a5a5a;
      background: #2d2d2d;
      border: 2px solid #5a5a5a;    
      // margin-bottom: 6px;
    }    
  }

  .step.active {
    color: #4bb543;
    .index {
      color: #4bb543;
      font-weight: 900;
      border: 2px solid #4bb543;        
    }
    .name {
      font-weight: 900;
    }
  }

  .step::after {
    position: absolute;
    content: "";
    border-bottom: 3px dotted #5a5a5a;
    width: 30%;
    top: 40px;
    left: 85%;
    z-index: 2;
  }

  .step.active::after {
    border-bottom: 3px dotted #4bb543;
  }  

  .step:first-child::before {
    content: none;
  }
  .step:last-child::after {
    content: none;
  }

}
</style>

<script>
export default {
  name: 'steps',
  data() {
    return {
        steps:[{
          idx: 0,
          ids:['generate','review'],
          name:'Generate',
          cta:'Use a source to generate some posts'
        },{
          idx: 1,
          ids:['edit'],
          name:'Edit',
          cta:'Make an edit to your post'
        },{
          idx: 2,
          ids:['preview'],
          name:'Preview',
          cta:'See what your final post looks like'
        }]
    }
  },
  props: {
    msg: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default:'generate'
    }
  }
}
</script>
