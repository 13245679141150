
<!--
IMPLEMENTS ONBOARDING WORKFLOW PER https://app.asana.com/0/1206858696947582/1208479501733448
-->

<template>
  <div class="onboarding-4" v-if="state">

    <div class="container-fluid heading nopad" v-show="showNav">
      <div class="row">
        <div class="col-md-12">
          <Nav2 :state="state" :session="session"  />
        </div>
      </div>
    </div>
    <div class="content">
      <Welcome :state="state" @on-action="onAction" v-if="state==='welcome'" />
      <Walkthru :state="state" @on-action="onAction" v-if="state==='walkthru'" />      
      <Generate :state="state" @on-action="onAction" v-if="state==='generate'" :sessionId="sessionId"/>
      <Results :state="state" @on-action="onAction" v-if="session && ['review','edit','preview'].includes(state)" :sessionId="session._id" mode="onboard" :help="help" @on-reset-session="onResetSession" />        
      <Model :state="state" @on-action="onAction" v-if="state==='model'" />
      <Connect :state="state" @on-action="onAction" v-if="state==='connect'" />      
    </div>
  </div>
</template>

<style lang="scss" >
.onboarding-4  {

  min-height: 100vh;  
  margin-top: 30px;
  font-family: Noto Sans;

  .heading {
    margin-bottom: 0px;
    border-bottom: 2px solid rgb(32, 199, 99);
    height: 200px;
  }

  .content {

    padding-top: 0px;

    button {
      font-size: 1rem;
      font-weight: normal;
      margin-top: 0px;
      border: 0px;
    }

  }
}
</style>

<script>

import Results from '@/components/Results/v3'
import Generate from './generate'
import Connect from './connect'

import Walkthru from './walkthru'
import Welcome from './welcome'
import Model from './model'
import Info from './info'
import Nav1 from './nav1'
import Nav2 from './nav2'

import { events, actions, getters } from '@/services/store'
import Onboarding from '@/services/onboarding'

// onboarding state in user.onboarding.state
// states per https://app.asana.com/0/1206858696947582/1208479501733448      
let STATES = ['welcome','walkthru','generate','review','edit','preview','model','connect','complete']

export default {
  name: 'Onboarding4',
  data() {
    return {
      STATES,
      user:undefined,
      help: '',
      state: '',   // view selector
      session:undefined,      
      sessionId:undefined,      
      dashboard:undefined,
      platform: undefined
    }
  },
  destroyed() {
    events.$off('user-resolved')
  },
  async created() {
    try {

      // watch user updates
      events.$on('user-resolved',()=>{
        this.user = getters.user()
      })

      this.user = getters.user()     
      this.dashboard = getters.dashboard()      

      let state = this.user.onboarding.state
      let sessions = await actions.fetchSessions({page:0,skip:0,limit:10,type:'*'})   

      if ( state === 'complete' ) {
        return this.$router.push(`/${this.dashboard._id}/start`)
      } else {
        if ( sessions.num ) {
          this.session = sessions.sessions[0]
          if ( ['review','edit','preview'].includes(this.user.onboarding.state) ) {
            this.setOnboardingState('review')
          } else {
            this.setOnboardingState(this.user.onboarding.state)
          }
        } else {
          this.setOnboardingState('welcome')
        }
      }
 
    } catch ( err ) {
      console.error(err)
    }
  },

  computed: {
    showNav() {
      let state = (this.state && ['generate','review','edit','preview'].includes(this.state))
      let session = this.session? this.session.postsCreated.all : true 
      return state && session 
    }
  },
  watch: {
    state() {
      this.setHelp(this.state)
    }
  },
  methods: {

    async onResetSession(args) {
      document.body.style.backgroundColor = '#F8F7F4';
      this.sessionId = args.session._id
      this.state = 'generate'
    },

    fetchHelp(state,args) {
      switch(state) {
        case 'edit': {
          if ( this.platform ) {
            let platform = this.$options.filters.channelname(this.platform)
            return `Here are your posts! Let's use this first ${platform} post as an example.<br/><br/>
            <b>Start by making an edit to your post.</b><br/><br/>
            Over time, your Voice Model will learn, and create a baseline of what works and what doesn't.<br/><br/>
            We can get you to third base most every post, but you may need to jump in with an edit for the home run 😎
            `    
          }
          break;
        }
        case 'preview': {
          return `Great! You can keep making edits to make your Voice Model stronger. <br/><br/>
          If you're satisfied with the post, <b>click on the 👁️ to Preview your post and see what it would look like.</b>`
          break;
        }
        default: return ''
      }
    },

    setHelp(state) {
      let help = this.fetchHelp(state)

      // edit state help shows in successindicator per https://app.asana.com/0/1206858696947582/1208776343719325
      if ( ['edit','preview'].includes(state) ) {
        this.help = help 
      } else {
        this.help = ''
        Onboarding.setHelpText(help)
      }
    },

    async setOnboardingState(state,args={}) {

      window.scrollTo(0,0);      

      args.state = state

      try {

        // TODO - better normalize these for legacy users
        args.version = 'v4'
        args.publisher = true
        args.generator = true 

        await Onboarding.setState(state,args)   
        this.setHelp(state)
        this.state = state

      } catch ( err ) {
        console.error(err)
      }
    },

    async onAction(evt) {
      // console.log('onboarding.v4.onAction',{action:evt.action,state:this.state,evt})
      switch (evt.action) {
        case 'welcome': {
          if (this.session) {
            await this.setOnboardingState('review')
          } else {
            await this.setOnboardingState('generate')
          }
          break;
        }
        case 'walkthru': {
          await this.setOnboardingState('generate')          
          break;
        }
        case 'generate': {
          this.session = evt.session
          await this.setOnboardingState('review')
          break;
        }
        case 'session-loaded': {
          this.session = evt.session
          // console.log('session-loaded',{state:this.state,posts:this.session.postsCreated.all,platform:this.platform,status:this.session.status.bucket})
          if ( !this.platform && this.session.postsCreated.all ) {
            let key = Object.keys(this.session.postsCreated).find((key)=>{
              return key !== 'all' && this.session.postsCreated[key]
            })
            this.platform = key
          }

          if ( this.state === 'generate' && this.session.postsCreated.all ) {
            await this.setOnboardingState('review')
          }
          break;
        }        
        case 'platform-selected': {
          this.platform = evt.platform
          this.setHelp('review')
          break;
        }
        case 'post-selected': {
          if ( !this.post || this.post._id !== evt.post._id ) {     
            this.post = evt.post
            this.setOnboardingState('edit')
          }
          break;
        }
        case 'content-edited': {
          await this.setOnboardingState('preview')    
          break;      
        }
        case 'preview-closed': {
          if ( evt.finish && this.state === 'preview') {
            await this.setOnboardingState('model')    
          }
          break;      
        }        
        case 'model': {
          await this.setOnboardingState('connect',{publisher:true})    
          break;      
        }       
        case 'connect-complete': {
          await this.setOnboardingState('complete',{generator:1,publisher:1})   
          this.$router.push(`/${this.dashboard._id}/sources`)          
          break;
        }             
      }
    },

  },

  components: {
    Walkthru,
    Generate,
    Welcome,
    Connect,
    Results,
    Model,
    Info,    
    Nav1,
    Nav2
  }
}
</script>

