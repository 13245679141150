<template>

    <div class="container-fluid session-history" v-if="policy && ready">

      <!-- remove confirm dialog -->
      <ConfirmDialog :open="removeDialogOpen" :title="removeDialogMessage" :click="confirmRemoveSession" />

      <!-- header -->
      <div class="row header-container nopad">
        <div class="container header-content">
          <div class="row">
            <div class="col-md-12 nopad header-content">
              <h2>History</h2>
              <p>All the sources you've generated posts from.</p>
              <div v-if="!sessions.length">
                <p><b>You have no prior sessions</b></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- filter -->
      <div class="row filter-container nopad">
        <div class="container filter-content ">
          <div class="row">
            <div class="col-12 nopad filter-actions">
              <SelectFilterActions :config="config" :dashboard="dashboard" :content="sessions" :selections="selections" @on-select="onSelect" @on-filter="onFilter" @on-action="onAction" />
            </div>
          </div>
        </div>
      </div>

      <!-- listview -->
      <div class="row session-container nopad">
        <div class="container session-content nopad">
          <div class="row nopad">
            <div class="col-12 session" v-for="(session,idx) in sessions" :key="session._id">
              <SessionListItem :session="session" :selections="selections" @on-select="onSelect" @on-action="onAction" :policy="policy" />
            </div>
          </div>
        </div>
      </div>

      <!-- pagination -->
      <div class="container">
      <div class="row" v-if="paging.pages > 1">
        <div class="col-md-12">
          <Pagination :paging="paging" @on-nav="nav" />
        </div>
      </div>
      </div>

    </div>

</template>

<script>

import SelectFilterActions from '@/components/SelectFilterActions'
import ConfirmDialog from '@/components/ConfirmDialog'
import Pagination from '@/components/Pagination'
import SessionListItem from './SessionListItem'
import Onboarding from '@/services/onboarding'

import { actions, getters, events } from '@/services/store'
import { includes } from '@/services/product'
import moment from 'moment'
import Vue from 'vue'

export default {

  name: 'Dashboard',

  data() {
    return {      
      ready:false,
      timer:false,
      sessions:[],
      helpText: '',      
      selections:[],
      policy:false,
      session: false,
      destroyed:false,
      dashboard:false,
      brand: {
        valid: false,
        updated: undefined
      },
      includes:includes,
      removeDialogOpen:false,
      removeDialogMessage: '',
      config: {
        actions: [{
          id:'remove',
          partner: '*',
          float:'left',
          title: {
            prefix: 'DELETE',
            suffix: 'SESSIONS',
          },
          variant:'outline-secondary'
        }],
        filter: {
          mediaType:{
            value: 'video,text',
            editable:true
          }
        }
      },
      paging: {
        skip: 0,
        page: 0,
        pages: 0,
        limit: 10,
        filter: '',
        type: 'text,video',
        sort: '-createdAt'
      }
    }
  },

  async created() {
    this.policy = await actions.fetchPolicy()
    this.dashboard = getters.dashboard()

    events.$on('dashboard-updated', this.updateBrandVoice )
    this.updateBrandVoice()

    await this.poll()
  },

  async destroyed() {
    this.destroyed = true;
    if ( this.timer ) {
      clearTimeout(this.timer)
      this.timer = false;
    }
  },

  methods: {

    updateBrandVoice() {
      let dashboard = getters.dashboard() 
      this.brand = dashboard && dashboard.brand_voice? {
         valid: dashboard.brand_voice.valid,
         updated: dashboard.brand_voice.updated
      } : {
        valid: false,
        updated: undefined
      }
      if ( this.brand.valid ) {
        this.updateHelpText()
      } 
    },

    async updateHelpText(page) {

      if ( this.brand.valid ) {

        // refresh the user
        let user = await actions.fetchUser()
        let subscription = getters.subscription() 

        // add help text when user is trialing and has not brand onboarded
        if ( user && subscription && user.onboarding.brand_available && !user.onboarding.brand_onboarded && subscription.status === 'trialing' ) {

          Onboarding.setHelpText(`<p>Want to see your generated results in your own voice instead of Lately's default voice?<br/><br/>
            Now that you've connected your social channels, you can!<br/><br/>
            Just click <b>Generate Again</b> and see the magic.<p>`)

        } else {

          Onboarding.setHelpText(``)

        }
        
      } else {
        this.helpText = ''
      }

    },

    nav(args) {
      if ( args.dir ) {
        this.paging.page += args.dir
      } else {
        this.paging.page = args.page
      }
      this.poll()
    },

    async poll() {

      if ( this.destroyed ) return;
      if ( this.timer ) {
        clearTimeout(this.timer)
        this.timer = undefined
      }

      try {

        const resp = await actions.fetchSessions(this.paging)

        // console.log('found sessions', {num:resp.num,actual:resp.sessions.length,paging:JSON.stringify(this.paging,0,1)})
        this.sessions = resp.sessions // .filter((s)=>{return s.status.bucket !== 'failed'})
        this.paging.pages = Math.ceil(resp.num/this.paging.limit)
        if ( this.sessions.find((s)=>{ return s.status.bucket !== 'complete' && s.status.bucket !== 'failed' && s.status.bucket !== 'open'} ) ) {
          this.timer = setTimeout( this.poll, 3000 )
        }

        Vue.nextTick(()=>{
          this.ready = true
        },250)

        this.updateHelpText(this.paging.page)

      } catch( err ) {
        this.$toasted.error(`We're having difficulty fetching your sessions just now, please try again in a few minutes`)
      }
    },

    onAction(ev,action) {
      if ( ev.action === 'view' ) {
        this.selectSession(ev.session)
      } else if ( ev.action === 'remove' ) {
        this.session = ev.session
        this.removeDialogMessage = this.session? `Remove Session?` : this.selections.length? `Remove ${this.selections.length} Sessions?` : `Remove ${this.sessions.length} Sessions?`
        this.removeDialogOpen = true
      }
      else if ( ev.action === 'regenerate' ) this.regenerateSession(ev.session)   
    },

    async newSession() {
      const resp = await actions.createSession('new')
      this.selectSession(resp.session,resp.count)
    },

    selectSession(session,count=1){
      if ( session.status.bucket.toLowerCase() === 'open' ) {
        this.$router.push(`/${session.dashboard}/generate/${session._id}/${count}`)
      } else this.$router.push('/'+session.dashboard+'/results/'+session._id)
    },

    removeSession(session){
      console.log('remove session', session)
      this.session = session;
      this.removeDialogMessage = 'Remove Session?'
      this.removeDialogOpen = true;
    },

    async confirmRemoveSession(confirm) {
      if ( confirm ) {
        const sessionIds = this.session? [this.session._id] : this.selections.length? this.selections.slice() : this.sessions.map((s)=>{return s._id})
        await actions.removeSessions(sessionIds)
        this.sessions = this.sessions.filter((s)=>{return !sessionIds.includes(s._id)})
        this.session = false
        this.selections = []
        this.removeDialogMessage = ''
        this.$toasted.success(`Removed ${sessionIds.length} sessions`)
      }

      this.removeDialogOpen = false;

    },

    regenerateSession(session) {
      this.$router.push(`/${session.dashboard}/generate/${session._id}/${1}`)
    },

    onSelect(session) {
      const idx = this.selections.indexOf(session)
      if ( idx === -1 ) this.selections.push(session)
      else this.selections.splice(idx,1)
    },

    onFilter(filter) {
      this.paging.type = filter.val.join(',')
      this.poll()
    }

  },

  components: {
    SelectFilterActions,
    SessionListItem,
    ConfirmDialog,
    ConfirmDialog,
    Pagination
  }

}
</script>

<style lang="scss" >

.session-history {

  height: 100vh!important;

  font-family: Noto Sans;

 .header-container {

    margin-top: 20px;

    background-color: #20C763;

    .header-content {
      color: #FFFFFF;
      background-color: #20C763;
      h2 {
        margin: 40px 0px 0px 0px;
        font-weight: bold;
        font-size: 40px;
      }
      p {
        margin-bottom: 16px;
      }
    }
  }

  .session-created {
    padding-left:5px;
  }

  .session-pagination {
    font-size:0.7em;
  }

  .session-pagination i {

  }

}

</style>
