<template>
    <div class="onboarding-nav ">
      <div class="row">
        <div class="col-md-12 text-center intro">
          <h3>This is a quick, interactive tutorial to show you how to use Lately like a rockstar!</h3>          
        </div>
        <div class="col-md-8 offset-2">
          <div class="steps container"> 
            <div class="text-center" v-for="(s,idx) in steps">
              <div class="step" :class="s.ids.includes(state)?'active':''" >
                  <p class="index"><span>{{ idx+1 }}</span></p>
                  <p class="name">{{ s.name }}</p>       
                  <p class="cta">{{ s.cta }}</p> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'steps',
  data() {
    return {
        steps:[{
          ids:['generate','review'],
          name:'Generate',
          cta:'Use a source to generate some posts'
        },{
          ids:['edit'],
          name:'Edit',
          cta:'Make an edit to your post'
        },{
          ids:['preview'],
          name:'Preview',
          cta:'See what your final post looks like'
        }]
    }
  },
  props: {
    msg: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default:'generate'
    }
  }
}
</script>

<style lang="scss" >
.onboarding-nav {

  padding: 10px 30px 10px 30px;
  background-color: #2d2d2d;
  z-index: 1000;

  .intro {
    h3 {
      margin: 25px;
      font-size: 20px;
      color: #c8c8c8;
    }
  }

  .steps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .step {
      display: inline;
      color: #5a5a5a;      
      font-size: 22px;      
      p {
          margin-bottom: 0px; 
      }
      p.index {
        font-size: 26px;
        font-weight: bold;
        span {
          margin: 10px;
          padding: 0px 10px 0px 10px;
          border: 1px solid;
          border-radius: 20px;
        }
      }
      p.name {
        font-weight: 600;
      }
      p.cta {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 25px;
      }

    }

    .step.active {
        color: rgb(32, 199, 99);
    }

  }
  
}
</style>
