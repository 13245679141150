<template>

  <div class="onboarding-walkthru">

    <carousel :per-page="1" :navigationEnabled="true" :paginationEnabled="false" @page-change="onPageChange" navigationNextLabel="NEXT" navigationPrevLabel="PREV">
      <slide>
        <video autoplay width="70%" ref="media-0">
            <source src="@/assets/onboarding/slides-1.mp4" type="video/mp4"/>
            Your browser does not support HTML5 video.
          </video>        
        </slide>
      <slide>
        <video width="70%" ref="media-1">
            <source src="@/assets/onboarding/slides-2.mp4" type="video/mp4"/>
            Your browser does not support HTML5 video.
          </video>        
      </slide>
      <slide>
        <div class="text-center">
        <video width="70%" ref="media-2">
            <source src="@/assets/onboarding/slides-3.mp4" type="video/mp4"/>
            Your browser does not support HTML5 video.
          </video>       
          <p v-if="canComplete">
            <b-button variant="primary" @click="next()">START CREATING CONTENT!</b-button>
          </p>        
          </div>
      </slide>    
    </carousel>  

  </div>

</template>

<style lang="scss" >

.VueCarousel {
  width: 100%;
}

.VueCarousel-navigation-button {
  font-family: Noto Sans;
  font-weight: bold;
  background-color: #42b983!important;
  color: white!important;
  border: 1px solid #42b983;
  padding: 5px 20px 5px 20px!important;
  border-radius: 4px;
}

.VueCarousel-navigation--disabled {
  display: none;
}

.VueCarousel-navigation-prev {
  position: absolute!important;
  top: 45%!important;
  left: 18%!important;
  z-index: 1000;
}

.VueCarousel-navigation-next {
  position: absolute!important;
  top: 45%!important;
  right: 18%!important;
  z-index: 1000;
}

.onboarding-walkthru {

  margin-top: 20px;
  text-align: center;

  .slide-3 {
    p {
      margin: 0px;
      padding: 5px 20px 5px 20px!important;
    }
  }

}

</style>


<script>

import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'Walkthru',
  data() {
      return {
        slide: 0,
        canComplete: false
      }
    },
    methods: {
      onPageChange(slide) {
        let player = this.$refs[`media-${slide}`]
        player.play()
        if ( slide === 2 ) {
          setTimeout(()=>{
            this.canComplete = true
          },5000)
        } else {
          this.canComplete = false
        }
      },
      next() {
        this.$emit('on-action',{
          action:'walkthru'
        })
      }
    },
    components: {
      Carousel,
      Slide
    }
}
</script>

